// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import classNames from 'classnames'

import isNull from 'lodash/isNull'
import kebabCase from 'lodash/kebabCase'
import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { compiler } from 'markdown-to-jsx'

import Slider from '../slider'
import '../slider/style.less'

import Gallery from '../gallery'
import '../gallery/style.less'

import Audio from '../audio'
import '../audio/style.less'

import Video from '../video'
import '../video/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** BlockParser */
const BlockParser = ({ block, locale }) => {
  let { type, subType, lang, content, resources, resource, caption } = block

  if (isNull(lang)) {
    lang = 'en'
  }

  let returnThis = <Fragment />

  if (lang === locale) {
    if (type === 'space') {
      returnThis = <br />
    }

    if (type === 'carousel') {
      returnThis = <Slider resources={resources} />
    }

    if (type === 'text') {
      returnThis = (
        <p className={classNames(subType)}>
          {compiler(content, { wrapper: null })}
        </p>
      )
    }

    if (type === 'title') {
      returnThis = (
        <h2 id={kebabCase(content)} className={classNames(subType)}>
          {content}
        </h2>
      )
    }

    if (type === 'image') {
      const image = getImage(resource)

      if (isNull(caption) === false) {
        returnThis = (
          <div className={classNames('flow im', subType)}>
            <GatsbyImage image={image} />
            <p className="caption">{caption}</p>
          </div>
        )
      } else {
        returnThis = (
          <div className={classNames('flow im', subType)}>
            <GatsbyImage image={image} />
          </div>
        )
      }
    }

    if (type === 'gallery') {
      const images = []
      map(resources, ({ resource }) => {
        images.push({
          data: resource,
          height: resource.childImageSharp.gatsbyImageData.height,
          width: resource.childImageSharp.gatsbyImageData.width,
        })
      })

      returnThis = (
        <Gallery className={classNames('flow', subType)} images={images} />
      )
    }

    if (type === 'video') {
      returnThis = (
        <Video className={classNames('flow', subType)} url={content} />
      )
    }

    if (type === 'audio') {
      returnThis = (
        <Audio className={classNames('flow', subType)} url={content} />
      )
    }
  }

  return returnThis
}

/** Wrapper */
const Wrapper = ({ block, locale }) => {
  const {
    type,
    subType = undefined,
    contentBlocks: childContentBlocks = {},
  } = block

  let returnThis = <Fragment />

  if (type === 'block') {
    returnThis = (
      <div className={classNames('block', 'as-paragraph', subType)}>
        <BlockParser block={childContentBlocks} locale={locale} />
      </div>
    )
  } else {
    returnThis = <BlockParser block={block} locale={locale} />
  }

  return returnThis
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default Wrapper
